import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { SingleClassContext } from "../context/SingleClassContext";

const useSchedule = () => {
  const [year, setYear] = useState(null);

  const {
    view,
    days,
    month,
    update,
    spinner,
    setMonth,
    end_date,
    start_date,
    setEndDate,
    getSchedule,
    setStartDate,
  } = useContext(SingleClassContext);

  const getStartDate = (view) => {
    if (year === null || month === null) return null;
    if (year !== null && year !== moment().year()) {
      return getStartNext(view, start_date);
    }
    const momentObject =
      month !== null && month !== moment().month()
        ? moment(month + 1, "M")
        : moment();
    if (view === "month") {
      return momentObject
        .startOf("month")
        .startOf("isoWeek")
        .format("YYYY-MM-DD");
    }
    return momentObject
      .startOf(view === "week" ? "isoWeek" : "month")
      .format("YYYY-MM-DD");
  };

  const getEndDate = (view, start_date) => {
    if (view === "month") {
      return moment(start_date)
        .endOf("isoWeek")
        .endOf("month")
        .format("YYYY-MM-DD");
    }
    return moment(start_date)
      .endOf(view === "week" ? "isoWeek" : "month")
      .format("YYYY-MM-DD");
  };

  const validDates = (start_date, end_date) => {
    return (
      start_date &&
      end_date &&
      start_date !== null &&
      end_date !== null &&
      moment(start_date).isValid() &&
      moment(end_date).isValid() &&
      moment(end_date).isAfter(moment(start_date))
    );
  };

  useEffect(() => {
    const initialMoment = moment();
    const currentMonth = initialMoment.month();
    if (month === undefined || month === null) {
      setMonth(currentMonth);
    }
    const currentYear = initialMoment.year();
    if (year === undefined || year === null) {
      setYear(currentYear);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (month !== undefined && month !== null) {
      const startDate = getStartDate(view);
      if (startDate !== null) setStartDate(startDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [month, view]);

  useEffect(() => {
    if (start_date && start_date !== null) {
      const endDate = getEndDate(view, start_date);
      setEndDate(endDate);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start_date]);

  useEffect(() => {
    fetchSchedule();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [end_date, update]);

  const fetchSchedule = (force = false) => {
    if (validDates(start_date, end_date) && !spinner) {
      getSchedule(
        {
          start_date,
          end_date,
        },
        force
      );
    }
  };

  const setNextMonth = () => {
    const currentMonth = moment(month + 1, "M");
    const newMonth = currentMonth.add(1, "month").month();
    if (month === 11) {
      const currentYear = moment(year, "YYYY");
      const newYear = currentYear.add(1, "year").year();
      setYear(newYear);
    }
    setMonth(newMonth);
  };

  const setPrevMonth = () => {
    const currentMonth = moment(month + 1, "M");
    const newMonth = currentMonth.subtract(1, "month").month();
    if (month === 0) {
      const currentYear = moment(year, "YYYY");
      const newYear = currentYear.subtract(1, "year").year();
      setYear(newYear);
    }
    setMonth(newMonth);
  };

  const getStartNext = (view, start_date) => {
    return moment(start_date)
      .add(1, view)
      .startOf(view === "week" ? "isoWeek" : "month")
      .startOf("isoWeek")
      .format("YYYY-MM-DD");
  };

  const getStartPrev = (view, start_date) => {
    return moment(start_date)
      .subtract(1, view)
      .startOf(view === "week" ? "isoWeek" : "month")
      .format("YYYY-MM-DD");
  };

  const handleNextWeek = () => {
    const next_week = getStartNext("week", start_date);
    const nextWeekStartMonth = moment(next_week).month();
    if (nextWeekStartMonth > month) {
      return setMonth(month + 1);
    }
    const weekStart = moment(start_date).week();
    const monthWeekStart = moment(month + 1, "M")
      .startOf("month")
      .week();
    if (month === 11 && monthWeekStart > weekStart) {
      return setNextMonth();
    }
    setStartDate(next_week);
  };

  const handlePrevWeek = () => {
    const prev_week = getStartPrev("week", start_date);
    const prevWeekStartMonth = moment(prev_week).month();
    if (prevWeekStartMonth < month) {
      setMonth(month - 1);
    }
    setStartDate(prev_week);
  };

  const monthString = moment(month + 1, "M").format("MMM");
  const yearString = moment(year, "YYYY").format("YYYY");

  const getWeekNumber = () => {
    if (month !== null) {
      const currentMonth = moment().month();
      if (month !== currentMonth) {
        const weekStart = moment(start_date).startOf("isoWeek").week();
        const monthWeekStart = moment(month + 1, "M")
          .startOf("month")
          .startOf("isoWeek")
          .week();
        const weekNumber =
          monthWeekStart > weekStart
            ? weekStart
            : weekStart - monthWeekStart + 1;
        return weekNumber;
      }
      const weekStart = moment(start_date).week();
      const monthWeekStart = moment(month + 1, "M")
        .startOf("month")
        .week();
      if (month === 11 && monthWeekStart > weekStart) return 5;
      const weekNumber =
        monthWeekStart > weekStart ? weekStart : weekStart - monthWeekStart + 1;
      return weekNumber;
    }
  };

  const weekNumber = getWeekNumber();

  return {
    days,
    month,
    end_date,
    weekNumber,
    start_date,
    getEndDate,
    yearString,
    monthString,
    getStartDate,
    setNextMonth,
    setPrevMonth,
    fetchSchedule,
    getWeekNumber,
    handleNextWeek,
    handlePrevWeek,
  };
};

export default useSchedule;
