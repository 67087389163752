import { useContext } from "react";
import { ModalContext } from "../../context/ModalContext";

const ConfirmDelete = ({ message, handleCancel, handleDelete }) => {
  const { clearModal } = useContext(ModalContext);

  return (
    <div>
      <p>{message}</p>
      <p>Esta acción NO puede deshacerse</p>
      <div className="row">
        <div className="col-6">
          <button
            onClick={() => {
              clearModal();
              if (typeof handleCancel === "function") handleCancel();
            }}
            className="btn w-100 text-muted"
          >
            Cancelar
          </button>
        </div>
        <div className="col-6">
          <button
            onClick={() => {
              clearModal();
              if (typeof handleDelete === "function") handleDelete();
            }}
            className="btn btn-danger w-100"
          >
            Eliminar
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmDelete;
