import React, { useContext } from "react";
import { SingleClassContext } from "../../context/SingleClassContext";
import SingleClassForm from "../../components/single_classes/SingleClassForm";
import ScheduleAdmin from "../../components/schedule/ScheduleAdmin";
import PanelTitle from "../../components/global/PanelTitle";
import { ModalContext } from "../../context/ModalContext";
import { AuthContext } from "../../context/AuthContext";
import useSchedule from "../../hooks/useSchedule";

const AdminClases = () => {
  const { clearClase, setPropiedadClase } = useContext(SingleClassContext);
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { user } = useContext(AuthContext);
  const { fetchSchedule } = useSchedule();

  const handleCallback = () => fetchSchedule(true);

  const handleCancel = () => {
    clearModal();
    clearClase();
  };

  const addClase = () => {
    modalComponent(
      "Agregar Clase",
      <SingleClassForm
        handleCallback={handleCallback}
        handleCancel={handleCancel}
        modifier={setPropiedadClase}
      />
    );
  };

  return (
    <div className="container-fluid">
      <PanelTitle
        title="Clases"
        onClick={addClase}
        hideButton={user.role === "coach"}
      />
      <ScheduleAdmin />
    </div>
  );
};

export default AdminClases;
